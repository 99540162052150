/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';

export { default as __metadata__ } from './metadata.json';
export const FiraSans_100Thin = require('./FiraSans_100Thin.ttf');
export const FiraSans_100Thin_Italic = require('./FiraSans_100Thin_Italic.ttf');
export const FiraSans_200ExtraLight = require('./FiraSans_200ExtraLight.ttf');
export const FiraSans_200ExtraLight_Italic = require('./FiraSans_200ExtraLight_Italic.ttf');
export const FiraSans_300Light = require('./FiraSans_300Light.ttf');
export const FiraSans_300Light_Italic = require('./FiraSans_300Light_Italic.ttf');
export const FiraSans_400Regular = require('./FiraSans_400Regular.ttf');
export const FiraSans_400Regular_Italic = require('./FiraSans_400Regular_Italic.ttf');
export const FiraSans_500Medium = require('./FiraSans_500Medium.ttf');
export const FiraSans_500Medium_Italic = require('./FiraSans_500Medium_Italic.ttf');
export const FiraSans_600SemiBold = require('./FiraSans_600SemiBold.ttf');
export const FiraSans_600SemiBold_Italic = require('./FiraSans_600SemiBold_Italic.ttf');
export const FiraSans_700Bold = require('./FiraSans_700Bold.ttf');
export const FiraSans_700Bold_Italic = require('./FiraSans_700Bold_Italic.ttf');
export const FiraSans_800ExtraBold = require('./FiraSans_800ExtraBold.ttf');
export const FiraSans_800ExtraBold_Italic = require('./FiraSans_800ExtraBold_Italic.ttf');
export const FiraSans_900Black = require('./FiraSans_900Black.ttf');
export const FiraSans_900Black_Italic = require('./FiraSans_900Black_Italic.ttf');
